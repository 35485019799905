
#staffList {
    margin-top: -15px;
    & table {
        border-spacing: 0px 12px;
    }
    .ant-table-thead > tr > th {
        background-color: transparent;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        /* identical to box height */
        letter-spacing: 0.01px;
        padding: 0 19px;
        color: #DADADA;
        border: none;
    }

    .ant-table-tbody > tr {
        cursor: pointer;
        & > td {
            border-top: 1px solid #4B5DB8;
            border-bottom: 1px solid #4B5DB8;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.01px;
            color: #000000;
            padding: 19px;

            &:first-of-type {
                border-left: 1px solid #4B5DB8;
                border-bottom-left-radius: 20px;
                border-top-left-radius: 20px;
            }

            &:last-of-type {
                border-right: 1px solid #4B5DB8;
                border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;
            }
        }
    }
}

.anticon.status-icon {
    font-size: 20px;
    &.icon-check {
        color: #5EEDC4;
    }
    &.icon-exclamation {
        color: #FF6565;
    }
} 

.vote-badge {
    font-size: 22px;
    
    & .ant-badge-count {
        background: #4B5DB8;
    }
}

.vote-badge-wrapper {
    position: absolute;
    right: -5px;
    top: -10px;
}

.caseTable table tr {
    cursor: pointer;
}

.certainty-rate--filled {
    background-color: #4B5DB8;
    display: inline-block;
    border-radius: 50%;
}
.certainty-rate--empty {
    background-color: #F4F5FB;
    display: inline-block;
    border-radius: 50%;
}