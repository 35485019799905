@import './Colors.scss';

#landingPitch {
    & h1 {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;

        /* identical to box height */
        letter-spacing: 0.009px;

        color: $primary;
    }

    & p {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;

        /* identical to box height */
        letter-spacing: 0.01px;
        color: $primary;
    }
}

.signinRow {
    padding: 0 20%;
}

#login-form {
    background-color: $white;
    padding: 80px 40px;
    border-radius: 18px;
    align-self:center;
    
    & .ant-form-item-control-input-content {
        text-align: center;
    }
}

.login-form-button.ant-btn {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */
    letter-spacing: -0.351px;
    &.login-form-button-filled {
        color: #FFFFFF;
        background: #4B5DB8;
        border: none;

        &:hover {
            background: #6a7cd4;
        }
    }

    &.login-form-button-inline {
        color: #4B5DB8;
        border: 0.5px solid #4B5DB8;

        &:hover {
            color: #6a7cd4;
            border: 0.5px solid #6a7cd4;
        }
    }
}

#login-form-forgot {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */
    letter-spacing: 0.01px; 
    height: 100%;
    color: #C2C4C7;
    border: none;
    background: transparent;
    cursor: pointer;
}


.neo-common-modal {
    width: 60% !important;
    top: 100px;

    & .ant-modal-content {
        border-radius: 18px !important;
        & .ant-modal-header {
            border-radius: 18px 18px 0 0 !important;
            border-bottom: none;
            & .ant-modal-title {
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                line-height: 32px;
    
                /* identical to box height */
                letter-spacing: 0.01px;
    
                color: #4B5DB8;
            }

        }
    }

}