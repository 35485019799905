@import '~antd/dist/antd.css';
@import './Colors.scss';
@import './Header.scss';
@import './Signin.scss';
@import './Form.scss';
@import './Font.scss';
@import './Button.scss';
@import './Table.scss';
@import './Staff.scss';
@import './Detail.scss';
@import './Presentation.scss';

.App {
  text-align: center;
}

#root {
  // min-height: calc( 100% - 0px );
}
body {
  background-color: $accent;
}
.ant-layout {
  background-color: $accent;
}

.pageRow {
  margin-top: 50px;
}

.centerAlignRow {
  align-items: center;
}

.fullHeight {
  height: 100%;
}
.fullHeightColumn {
  height: calc( 100vh - 150px );
}
.maxElemHeightCol {
  height: max-content;
}
.neoSider {
  background-color: transparent;
}

#neoSidenav {
  background-color: transparent;
  border-right: 2px solid #C4C4C4;
  height: calc( 100vh - 150px );
  margin-top: 30px;
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;

    &::after {
      border-right: 2px solid #4B5DB8;
    }

    & a {
      border-radius: 10px;
      background-color: rgba(75,93,184, 0.2);
      mix-blend-mode: normal;
      & .anticon {
        color: #4B5DB8;
        opacity: 1;
      }
    }
  }

  & li.ant-menu-item {
    height: 50px;
    width: calc(100% + 2px);
    text-align: center;
    margin: 27.5px 0;
    padding: 0 25px;

    & a {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #B6B8BC;

      &:hover {
        border-radius: 10px;
        background-color: rgba(75,93,184, 0.2);
        & .anticon {
          color: #4B5DB8;
          opacity: 1;
        }
      }
    }

    & .anticon {
      font-size: 25px;
      margin: 0;
    }
  }
}

.navAvatar.ant-card {
  border: none;
  & .ant-card-body {
    padding: 5px 12px;
    background: #F5F7FD;
    border-radius: 15px;
    height: 45px;

    & .ant-card-meta-avatar {
      margin: 5px 0;
    } 
    & .ant-card-meta-detail {
      padding: 7px 0;
      text-align: start;
      & .ant-card-meta-title {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #4B5DB8;
        margin-bottom: 5px;
      }
      & .ant-card-meta-description {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 11px;
        color: #C7C7C7;        
      }
    }

  }
}
// .page-row {
//   height: 100%;
// }
.page-column{
  background-color: #ffffff;
  // height: 100%;
  margin: 0 20px;
  border-radius: 10px;
  padding: 33px 40px;
}

.home-main-col {
  margin-left: 10%;
}

.page-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.01px;
  color: #4B5DB8;
  margin-bottom: 25px;
}

.page-sub-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01px;
  color: #4B5DB8;
  border-bottom: 2px solid #4B5DB8;
  width: 100%;
  margin-bottom: 20px;
  &.page-sub-title-wob {
    border-bottom: none;
  }
}


.page-content-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.76px;
  color: #B6B8BC;
  margin-bottom: 20px;
}

.user-info {
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height */
  letter-spacing: 0.01px;

  color: #000000;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  right: 0.5px;
}

.hidden {
  display: none !important;
}