
@import './Colors.scss';

// ================== HEADER ==================
#navBrand a {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.05px;
    color: $primary;
    align-self: center;
    margin: 0;
}
#neoNav {
    text-align: right;
    background-color: transparent;
    border-bottom: none;
    display: flex;
    align-items: center;
    height: 100%;
}

#layout-header {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    height: 75px;
    align-items: flex-end;
    &.publicHeader {
        padding: 0 20%;
    }
    &.userHeader {
        padding: 0 7%;
    }
}
#neoNav li.ant-menu-item {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    border: none;
    display: flex;
    letter-spacing: 0.009px;
}

#neoNav li.ant-menu-item a {
    color: $secondary;
}
#neoNav li.ant-menu-item-selected,
#neoNav li:hover {
    border: none;
}

#neoNav li.ant-menu-item-selected a,
#neoNav li:hover a {
    color: $primary;
    border: none;
}

// ================== END OF HEADER ==================