.ant-btn {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: -0.351px;
    min-width: 200px;
    height: 56px;
    border-radius: 20px;
}

#signOutBtn {
    width: max-content;
    height: max-content;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    min-width: max-content;
}

#createCaseBtn,
#createMeetingBtn,
#addLinkToCaseBtn,
#addPossibilityToCaseBtn,
.dashedFullWidthButton {
    border: 1px dashed #4B5DB8;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01px;
    color: #B6B8BC;
    & .anticon {
        color: rgba(75,93,184, 0.2);
        font-size: 27px;
    }
}
.dashedFullWidthButton {
    margin: 10px 0;
    border: 1px solid #4B5DB8;

}

#modify-profile-btn {
    margin: 0 5px;
    background: #4B5DB8;
    color: white;
}
#change-password-btn {
    margin: 0 5px;
    background: #4B5DB8;
    color: white;
}
.edit-profile-button {
    color: white;
    background: #79F8D4;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */
    letter-spacing: -0.981px;

    color: #FFFFFF;

    &:hover {
        color: #79F8D4;
        border: 1px solid #79F8D4;
        background: #FFFFFF;
    }
}

.ant-picker-ok .ant-btn.ant-btn-primary.ant-btn-sm{
    width: 100%;
    height: 100%;
    line-height: 34px;
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    transition: color 0.3s;
    font-size: 14px;
    font-variant: tabular-nums;
    font-weight: normal;
    border: none;
    box-shadow: none;
}

.invisibleButton.ant-btn {
    padding: 0;
    margin: 0;
    border: none;
    width: max-content;
    height: max-content;
    min-width: max-content;
}

.neo-primary-btn {
    background: #4B5DB8;
    margin: 40px 20px;
}

#submit-vote-button,
#submit-vote-and-diagnose-button {
    border: 1px solid #4B5DB8;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.66px;
    color: #4B5DB8;
    padding: 10px 20px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;

    & .anticon {
        font-size: 22px;
    }
}

#add-diagnose-button {
    border: 1px solid #4B5DB8;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.66px;
    color: #4B5DB8;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .anticon {
        font-size: 22px;
    }
    &-wrapper {
        display: flex;
        margin-bottom: 10px;
        justify-content: end;
    }
}

.commentButton {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.33px;
    color: #4B5DB8;
    &:focus {
        color: #4B5DB8;
    }
    &:hover {
        color: #6b77b4;
    }
}

.comment-button-wrapper {
    width: 100%;
    text-align: end;
    margin-bottom: 10px;
}

#profile-button-wrapper,
#modify-case-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
}


.ant-btn.cancel-delete,
.ant-btn.approve-delete {
    width: max-content;
    min-width: max-content;
    padding: 10px 25px;
    font-size: 14px;
    line-height: 16px;
    height: min-content;
}
