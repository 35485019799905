h1.ant-typography.vote-block-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #C7C7C7;
}

h1.ant-typography.vote-question-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    color: #4B5DB8;
}

h1.ant-typography.vote-presentation-mode-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #4B5DB8;
    text-align: center;
    width: 90%;
    border-bottom: 2px solid #4B5DB8;
}

#vote-mode-wrapper {
    width: 50%;
    margin: auto;
}

.vote-presentation-slider .ant-slider-handle {
    display: none;
}