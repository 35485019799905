.ant-typography {
    
    &.inline-label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        display: inline;
        font-size: 9px;
        line-height: 11px;
        color: #B6B8BC;
        margin: 8px 15px;
        min-width: 65px;
    }

    &.inline-value {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        display: inline;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.165px;
        color: #4B5DB8;
        margin: 8px 15px;
    }

    &.case-number {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: #4B5DB8;
        margin: 8px 15px;
    }

    &.detail-column-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        
        /* identical to box height */
        letter-spacing: -0.25px;
        margin-bottom: 20px;   
        color: #4B5DB8;
    }

    &.detail-column-label{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #C7C7C7;
        display: block;
        margin-bottom: 0 !important;
    }

    &.detail-column-value{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        display: block;
        margin-top: 0 !important;
        white-space: pre-line;
    }

    &.vote-column-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #C7C7C7;
        margin: 10px 15px;
    }
    &.vote-column-opinion-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #4B5DB8;
        margin: 10px 15px;
    }

}
.toHomeLink {
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.06px;
    color: #4B5DB8;
    margin: 8px 15px;
}

.detail-user-icon {
    color: black;
    &-wrapper {
        margin: 8px 15px;
        min-width: 65px;
    }
}
.detail-row {
    display: flex;
    align-items: center;
}

.white-corner-col {
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    height: max-content;
}

.inline-rate{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    display: inline;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.165px;
    color: #4B5DB8;
    margin: 8px 15px;
}

.background-detail-column {
    padding: 30px !important;
}

.link-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.01px;
    color: #4B5DB8;
}

.link-button {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.351px;
    width: 200px;
    height: 56px;
    border-radius: 20px;
    background-color: #4B5DB8;
    color: #FFFFFF;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-column {
    padding: 20px 35px !important;
    margin-left: calc(29.2% + 25px);
    border-radius: 20px;
    background-color: white;
    margin-bottom: 10px;
}

.link-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

