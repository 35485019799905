@import './Colors.scss';

.ant-input,
.ant-picker {
    height: 55px;
    border: 1px solid #B6B8BC;
    padding: 18px 26px;
    border-radius: 18px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    /* identical to box height */
    letter-spacing: 0.01px;

    color: #000000;
}

.ant-picker-input > input {
    font-size: 16px;

}


.ant-input:hover {
    border: 1.5px solid #79F8D4;
}

.ant-input:focus,
.ant-input-focused,
.ant-picker:focus,
.ant-picker:hover,
.ant-picker-focused {
    border: 1.5px solid #79F8D4;
    box-shadow: none;
}
.ant-input::placeholder,
.ant-picker::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    // line-height: 20px;
    /* identical to box height */
    letter-spacing: -0.07px;
    color: #B6B8BC;
}

.ant-divider-horizontal.ant-divider-with-text.primary-divider {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    border-top-color: rgba(75, 93, 184, 0.5);
    color: rgba(75, 93, 184, 0.5);
    /* identical to box height */
    letter-spacing: 0.01px;
}
.ant-divider-horizontal.ant-divider-with-text.primary-divider .ant-divider-inner-text {
    padding: 0 30px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
    border: 1px solid #B6B8BC;
    padding: 18px 26px;
    border-radius: 18px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    /* identical to box height */
    letter-spacing: 0.01px;

    color: #000000;
}

.ant-select-selection-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    // line-height: 20px;
    /* identical to box height */
    letter-spacing: -0.07px;
    color: #B6B8BC;
    bottom: 10px;
    position: absolute;
}

.ant-select-selection-item {
    bottom: 10px;
    position: absolute !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1.5px solid #79F8D4;
    box-shadow: none;
}
.ant-select-selector:hover {
    border: 1.5px solid #79F8D4 !important;
}

.submitBtnFormItem{
    text-align: end;
}

h1.ant-typography.formSectionLabel {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    /* identical to box height */
    letter-spacing: 0.01px;
    margin-bottom: 24.5px;
    color: #B6B8BC;
}
.lastFormItemInSection{
    margin-bottom: 50px;
}

.ant-modal-body {
    padding: 0px 24px;
}

.silent-label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01px;
    color: #DADADA;
}

#create-case-steps {
    display: none;
}

#certainty .ant-radio-wrapper {
    display: block;
    height: 30px;
    line-height: 30px;

    & span:last-of-type {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01px;
        color: #B6B8BC;
    }

    & .ant-radio-checked .ant-radio-inner{
        border-color: #F5F7FD;
    }
    & .ant-radio-inner {
        border-width: 2px;

        &::after {
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
    }
}





h1.ant-typography.radioLabel {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    /* identical to box height */

    color: #4B5DB8;
}

.steps-action {
    padding: 10px 0;
    text-align: end;
}

.give-opinion-text {
    background-color: #F5F5F5;
    border: 1px dashed #4B5DB8;
    margin: 10px 15px;
    &:hover,
    &:focus {
        border: 1px solid #4B5DB8;
    }
}

.add-vote-panel {
    background-color: transparent;
    & > .ant-collapse-item {
        border: none;
    }

    &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
        height: 45px;
        & .ant-collapse-arrow {
            left: 50%;
            font-size: 36px;
            color: #4B5DB8;
            padding: 0;
        }
    }
}

h1.ant-typography.possibility-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #4B5DB8;
}

#lifeStyle.ant-input {
    min-height: 55px !important;
}

.vote-parent-form .ant-slider {
    // width: calc( 100% - 250px);
    margin: 10px 20px;
}

.vote-slider-wrapper {
    display: flex;
    justify-content: space-around;

    .ant-form-item {
        width: calc( 100% - 200px);
    }

    &.case-form-vote-wrapper {
        & .ant-row.ant-form-item {
            width: calc( 100% - 150px);
            margin: 0 5px;
        }
    }
}


.vote-parent-form .add-comment-panel .ant-collapse-header {
    display: block;
    width: 20px;
    float: right;
    top: -70px;

    & .ant-collapse-arrow {
        font-size: 26px;
        color: #4B5DB8;
    }
}

textarea.ant-input {
    min-height: 57px;
}
