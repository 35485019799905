.staff-detail-section-label.ant-typography {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01px;
    color: #4B5DB8;
}

.staff-detail-field-label.ant-typography {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01px;
    color: #DADADA;
    margin-bottom: 0px;
}

.staff-detail-field-value.ant-typography {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01px;
    color: #000000;
}
